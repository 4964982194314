@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap');

.display-linebreak {
    white-space: pre-line;
    font-weight: 500;
    font-size: 0.85rem;
    line-height: 20px;
}

.inputRounded {
    border-radius: 37.5px;
    width: 24rem;
    padding: 0.469rem 0.5rem 0.469rem 1rem;
    border-style: none;
    background-color: #EEEFF3;
    outline-style: none;
}
.inputRounded:focus{
    background-color: white;
    border: 0.75px solid #2D94C9;
}

.recent {
    margin: 2.25rem 0 .9375rem 0;
    font-size: 13.5px;
    font-weight: 700;
    line-height: 16.5px;
    letter-spacing: 0;
    text-align: left;

}
.post-question-box{
font-size: 12px;
font-weight: 600;
line-height: 15px;
letter-spacing: 0;
text-align: left;

}

.align-spinner{
    display: flex;
    justify-content: center;
}

.render-post {
    /* box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25); */
    border-radius: 6px;
    background-color: #f9f9f9;
    margin: 12px 22.5px;
    /* max-height: 400px; */
    overflow: hidden;
    /* width: 80%; */
}

.post-profile {
    border-bottom: 1px solid #D2D2D2;
    padding: .75rem 0 .75rem 1.5rem;
}

.profile-details {
    font-size: 15px !important;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 0;
    text-align: left;

}

.update-details {
    margin-top: 5px;
    font-size: 11px;
    font-weight: 600;
    line-height: 11.25px;
    letter-spacing: 0;
    text-align: left;
    color: #2D94C9;
}

.line-container {
    background-color: #2D94C9;
    color: white;
    font-size: 10.5px;
    margin-top: -0.5375rem;
    padding-left: 1.5rem;
    height: 10px;
}

.group-details p {
    padding-left: 1.125rem;
    min-height: 14px;
    padding: 0.3rem;
    font-size: 8.75px;
    font-weight: 700;
    line-height: 14px;
    letter-spacing: 0;
    text-align: left;

}

.questions{
    padding-left: 1.5rem;
    padding-bottom: .75rem;
    /* border-bottom: .75px solid #D2D2D2; */
}
.questions p{
font-size: 0.8rem !important;
font-weight: 600;
line-height: 15px;
letter-spacing: 0em;
text-align: left;

}
.post-data{
    color: black;
font-size: 10.5px;
font-weight: 550;
line-height: 24px;
letter-spacing: 0em;
text-align: left;

}
.footer{
    display: flex;
    justify-content: flex-start;
    margin-left: 1rem;
    padding: .75rem 0;
    height: 1rem;
    gap: 0.5rem;
    user-select: none;
}
.footer span{
font-size: 12px;
font-weight: 550;
line-height: 12.75px;
letter-spacing: 0em;

}

.feed-page-add-post-button.Mui-disabled {
    /* border-radius: 1px solid #1976d2; */
    background-color: #bbdefb;
}

.no-posts-message{
    text-align: center;
    padding: 50px 0;
    font-family: "Montserrat";
    font-size: 1.25rem;
}

.post-profile-avatar {
    width: 33px;
    height: 33px;
    font-size: 14px;
    font-family: "Montserrat";
    color: white;
    font-weight: 600;
    background-color: orange;
    text-transform: capitalize;
    border-radius: 16.5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form-container {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
    gap: 20px;
}

.form-container .input-field-label {
    margin: 0;
    font-size: 12px;
}

.form-container .buttons-container {
    display: flex;
    flex-direction: column;
}

.MuiMenu-list {
    max-height: 160px;
}

.group-name-heading {
    font-size: 12px;
    margin: 5px 1.5rem;
    font-weight: 600;
}

/* .post-dropdown{
    position: relative;
}
.post-dropdown-menu{
    position: absolute;
    padding: 0.25rem 1rem 0rem 0.5rem;
    top: 1.5rem;
    left: 15rem;
    border: 1px solid gray ;
    border-radius: 5px;
    background-color: white;
} */