
.groups-heading {
  font-family: "Montserrat";
  font-style: "normal";
  font-weight: 600;
  font-size: "13.5px";
  line-height: "17.5px";
  color: "#333333";
  display: flex;
  justify-content: space-between;
  width: 89%;
}

.groups-cards-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.groups-card-div {
  cursor: pointer;
  height: 22rem;
  width: 11.84rem;
  display: flex;
  flex-direction: column;
  background-color: #4db8ef;
  border: 0.5px solid #d2d2d2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
} 

 .groups-image-div {
  background-color: white;
  height: 12rem;
}

.groups-image-div img {
  width: 136.5px;
  object-fit: cover;
  margin: 26px;
}

.groups-card-details {
  height: 25%;
  height: max-content;
  display: flex;
  flex-direction: column;
  margin: 0.75rem 1.266rem;
  gap: 0.56rem;
}

.groups-title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  margin: 0;
} 



.groups-members-count {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 10.5px;
  line-height: 12.75px;
  color: #333333;
  margin: 0;
}

.edit_delete_icons{
  display: flex;
  padding-left: 70%;
}

/* .addGroup_button{
  display: flex;
  justify-content: end;
} */

.form-container{
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  gap: 20px;
}

.form-container .input-field-label {
  margin: 0;
  font-size: 12px;
}

.form-container .buttons-container {
  display: flex;
  flex-direction: column;
}

.flex-container {
  flex-direction: column
}

/* .flex-container > div {
  background-color: #f1f1f1;
  width: 100px;
  margin: 10px;
  text-align: center;
  line-height: 75px;
  font-size: 30px;
} */
/* 
.img_div{
  cursor: pointer;
  height: 17rem;
  width: 11.84rem;
  display: flex;
  flex-direction: column;
  background-color: #4db8ef;
  border: 0.5px solid #d2d2d2;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

} */

.img_div> img {
  width: 136.5px;
  object-fit: cover;
  margin: 26px;
  background-color: white;
  height: 12rem;
}