.navbar {
    height: 4.75rem;
    padding: 0 4.687rem;
    background: #FFFFFF;
    z-index: 2;
    position: sticky;
    top: 0;
}

.navbar-searchbox {
    box-sizing: border-box;
    height: 2.875rem;
    width: 50.44rem;
    padding: 0 0.966rem;
    background: #FFFFFF;
    border: 0.35px solid #D2D2D2;
    border-radius: 15px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14.25px;
    color: #6E6E6E;
    margin-left: 150px;
}

.navbar-search-icon {
    position: absolute;
    top: 0.60rem;
    right: 0.7125rem;
    color: #6D6D6D;

}

.right-div {
    display: flex;
    align-items: center;
}

.notification-icon {
    cursor: pointer;
    color: #6D6D6D;
    position: relative;
}

.notification-count{
    position: absolute;
    right: 0;
    width: 11.115px;
    height: 11.115px;
    border-radius: 5.56px;
    color: #ffffff;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 7.5px;
    line-height: 10.5px;
    background-color: #2D94C9;
    text-align: center;
}

.vertical-line {
    width: 0;
    height: 1.219rem;
    border: .75px solid #E3E2E2;
    margin: 0 18.75px;
}

.navbar-profile-image {
    cursor: pointer;
    height: 36px;
    width: 36px;
    object-fit: cover;
    border-radius: 18px;
}

.nimap-logo {
    height: 3.344rem;
    padding-left: 1rem;
    cursor: pointer;
}