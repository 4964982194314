::-webkit-scrollbar {
  width: 0.3rem;
  height: 0.3rem;
}

::-webkit-scrollbar-thumb {
  background-color: #a2dfff;
  border-radius: .4875rem;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #63b8ec;
}

*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: 'Montserrat', sans-serif;
}

.sidebar {
  height: 28.83rem; /* 18.86rem */
  width: 11.719rem;
  padding-left: 1.125rem;
  padding-right: 1.875rem;
  text-align: left;
  /* display: grid; */
  /* width: 20vw; */
  /* transition: width 0.3s; */
  /* color: white; */
  background: #ffffff;
  /* box-shadow: 1px 0 3px #aaa; */
  border-radius: 1.5px;
}

.sidebar__ul {
  padding-left: 1.125px;
  display: grid;
  list-style-type: none;
  align-content: start;
  overflow-x: hidden;
}
.sidebar__ul--1 {
  margin: 0;
  margin-top: 2.51rem;
  overflow: hidden;
}
.sidebar__ul--2 {
  /* height: 92vh; */
  overflow-y: scroll;
  padding-bottom: 1.875rem;
  margin: 0;
  transition: all 0.2s;
  /* padding-left: 2vw; */
}

/* .sidebar__ul--2.small {
  padding-left: 32px;
} */
.sidebar__ul--2::-webkit-scrollbar {
  display: none;
}
/* .sidebar__ul--2:hover::-webkit-scrollbar {
  display: block;
} */
.sidebar__ul--2 ::-webkit-scrollbar-track {
  border-radius: 0rem;
}
.sidebar__ul--li {
  display: flex;
  align-items: center;
  color: white;
  text-transform: capitalize;
  font-size: .469rem;
  height: 3.1rem;
  /* height: 2.8rem; */
}

.sidebar__ul--li.first {
  height: 3.1rem;
}


/* .sidebar__ul--li-head-span {
  display: grid;
  justify-self: end;
  margin-left: 12vw;
  cursor: pointer;
}

.sidebar__ul--li-head-span.small {
  margin-left: 3px;
} */
/* .sidebar__ul--li-head-arrow {
  cursor: pointer;
  height: 2.375rem;
  width: 3.75rem;
  display: grid;
  align-content: center;
  justify-content: center;
  transition: all 0.2s;
}
.sidebar__ul--li-head-arrow:hover {
  color: #63b8ec;
}
.sidebar__ul--li-title {
  font-size: 0.8rem;
  text-transform: uppercase;
  justify-self: start;
  padding: 1rem 0;
  margin-top: 1.1rem;
  margin-bottom: 0;
  color: #63b8ec;
  cursor: pointer;
  user-select: none;
} */
/* .sidebar__ul--li-title1 {
  font-size: .69rem;
  text-transform: uppercase;
  justify-self: start;
  padding: 0rem 1.56rem;
  margin-top: 0rem;
  margin-bottom: 0;
} */
.sidebar__ul--li-nav {
  width: 100%;
  display: grid;
  align-items: center;
  height: 100%;
  grid-auto-flow: column;
  justify-content: start;
  color: #333333;
  text-decoration: none;
  padding-right: 1.4rem;
  transition: all 0.2s;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 13.5px;
  line-height: 12.75px;
}

.sidebar__ul--li-nav .menu-name {
  height: 35px;
  padding-left: 0.9375rem;
  width: 150px;
  display: flex;
  align-items: center;
}

.sidebar__ul--li-nav.first .menu-name{
  margin-top: 10px;
}
/* 
.sidebar__ul--li-nav-1 {
  padding: .93rem 1.56rem;
} */
.sidebar__ul--li-nav-i {
  color: #d0edff;
}
.sidebar__ul--li-nav:hover .sidebar__ul--li-nav-i {
  color: #63b8ec;
}
/* .sidebar__ul--li-nav:hover {
  color: black;
  font-size: 0.75rem;
  transform: scale(1.05);
  text-decoration: none;
} */
.sidebar__ul--li-nav.active {
  font-weight: 700;
  color: #1083BC;
}

.sidebar__ul--li-nav.active .menu-name{
  background-color: #f5f5f5;
}

.sidebar__ul--li-nav.active .sidebar-subitems-icons{
  color: #ffffff;
}

.sidebar__ul--li-nav-active::after {
  content: "";
  height: 1.41rem;
  width: 3.75px;
  background-color: #63b8ec;
  position: absolute;
  top: 50;
  right: 0;
  border-top-left-radius: 2.25px;
  border-bottom-left-radius: 2.25px;
}
.sidebar__ul--li-nav-active .sidebar__ul--li-nav-i {
  color: #63b8ec;
}
.sidebar-subitems-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.66);
  width: 2.744rem;
  height: 100%;
  margin-top: auto;
  background-color: #2A78A2;
  /* border-radius: 30px */
  /* padding-bottom: 2.188rem; */
}

.sidebar-subitems-icons.first {
  border-radius: 1.406rem 1.406rem 0 0;
  padding-top: 5.25px;
  /* padding-bottom: 2.188rem; */
}

.sidebar-subitems-icons.last {
  border-radius: 0 0 1.406rem 1.406rem;
  padding-bottom: 5.25px;
}

.sidebar-hr{
  height: 0;
  border: 0.375px solid #d2d2d2;
}

.sidebar-profile-details{
  user-select: none;
  display: flex;
  height: 3.5rem;
  overflow-y: scroll;
}

.profile-image {
  cursor: pointer;
  width: 2.544rem;
  height: 2.544rem;
  border-radius: 1.172rem;
  /* background-image: url("https://www.himalmag.com/wp-content/uploads/2019/07/sample-profile-picture.png"); */
}

.profile-info {
  padding-left: .9375rem;
}

.profile-name {
  /* height: .9375rem; */
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 15px;
  color: #333333;
  padding-top: 1.5px;
  margin-bottom: 0;
  text-transform: capitalize;
}

.profile-role {
  padding-top: 4.5px;
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 12.5px;
  line-height: 12.75px;
  color: #333333;
  text-transform: capitalize;
}