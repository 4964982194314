.home-layout {
  display: flex;
  background-color: #F5F5F5;
  /* padding-bottom: 66px; */
}

.homepage-container {
  width: 100%;
}

.homepage-content {
  display: flex;
  gap: 2.975rem;
  margin: 2.5rem 4.69rem;
  min-height: calc(100vh - 100px);
}

.outlet-div {
  width: calc(100vw - 100px);
}

.current-page {
  font-weight: bolder;
}

.side-panel{
  display: flex;
  flex-direction: column ;
  gap: 1.125rem;
  position: sticky;
  top: 121px;
}

.side-group-card {
  height: 7rem;
  width: 12.4rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  padding: 18px;
  background: white;
}
.side-group-tag{
  display: flex;
   align-items: center;
   gap: 15px;
   margin-bottom: 12px;
}

.side-group {
  font-weight: 600;
  font-size: 13.5px;
  line-height: 16.5px;
}

.side-group-icon{
  background-color:rgba(45, 143, 194, 0.2);
  border-radius: 4px;
  height:36px;
   width:36px;
   display: flex;
   align-items: center;
   justify-content: center;
}

.side-group-details{
  background: linear-gradient(180deg, #2E84B2 0%, #2D94C9 100%);
  padding: 12px;
}
.side-group-details p{
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 0rem;
  text-align: left;
  color: white;
}

.side-group-details span{
font-size: 10.5px;
font-weight: 600;

text-align: left;

}